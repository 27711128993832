.container {
	display: flex;
	flex-direction: column;
}

.dateTimePreference {
	padding-left: 26px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
}

.subPreference {
	padding: 26px;
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 16px;
}

.cardContainer {
	flex: 1;
}

.card {
	padding: 10px;
	border: 1px solid #e0e0e0;
	width: 100%;
}

.successRateRatioContainer {
	display: flex;
	gap: 16px;
}

.successRateRatioInputs {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
}

.infoText {
    font-size: 12px;
    margin-bottom: 24px;
}