.modal {
	position: relative;
	background: #fff;
	border: 1px solid #f0f0f0;
	box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
}

.titleContainer {
	padding: 24px 24px 32px 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modalTitle {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	margin-bottom: 0;
	color: #242529;
}

.closeIcon {
	width: 25px;
	height: 25px;
	stroke: #000;
	transition: stroke 0.2s;
	cursor: pointer;
}

.closeIcon:hover {
	stroke: #aaa;
}

.modalContent {
	position: relative;
	max-height: calc(100vh - 50px);
	overflow-y: auto;
}

.modalContent::-webkit-scrollbar {
	width: 8px;
	height: 10px;
	background: transparent;
}

.modalContent::-webkit-scrollbar-thumb {
	background: #d2d2d2;
	border-radius: 30px;
}

.actionButtons {
	display: flex;
	justify-content: flex-end;
	gap: 16px;
	margin-top: 24px;
	z-index: 11;
}

.options,
.dropdownContent {
	display: grid;
	gap: 4px;
	padding: 16px;
	user-select: none;
}

.option {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 12px;
	cursor: pointer;
	transition: background 0.2s;
}

.option:hover {
	background: var(--bg-20);
}

.optionDisabled {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 12px;
	cursor: not-allowed;
	transition: background 0.2s;
	color: rgba(0, 0, 0, 0.25);
}

.optionDisabled p {
	font-size: 15px;
	line-height: 20px;
	letter-spacing: 0.25px;
	margin: 0;
	white-space: nowrap;
	color: rgba(0, 0, 0, 0.25);
}

.option:hover .chevron,
.option:hover .dropdown {
	visibility: visible;
	opacity: 1;
}

.option p {
	font-size: 15px;
	line-height: 20px;
	letter-spacing: 0.25px;
	margin: 0;
	color: var(--black-100);
	white-space: nowrap;
}

.optionTitleContainer {
	display: flex;
	align-items: center;
	gap: 8px;
}

.dot {
	width: 12px;
	height: 12px;
	background: var(--black-100);
	border-radius: 50%;
}

.chevron,
.dropdown {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0.2s, opacity 0.2s;
}

.subDropdown {
	overflow: visible !important;
}

.dropdown {
	position: absolute;
	top: 0;
	left: 100%;
	min-width: 195px;
	padding-left: 20px;
}

.dropdownContent {
	background: #ffffff;
	border: 1px solid var(--bg-50);
}

.approveDescription {
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 24px;
	color: #252525;
}

.tables {
	display: grid;
	gap: 25px;
}

.invoiceNo,
.invoiceNoSecondary span {
	font-weight: 600;
	letter-spacing: 0.25px;
	margin-bottom: 16px;
	color: var(--black-100);
}

.invoiceNo {
	font-size: 13px;
	line-height: 20px;
}

.invoiceNoSecondary,
.invoiceNoSecondary span {
	font-size: 16px;
}

.invoiceNoSecondary {
	font-weight: 400;
	color: #252525;
}

.invoiceNoSecondary span {
	line-height: 24px;
}

.actionButtonsSticky {
	position: sticky;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: flex-end;
	gap: 10px;
	padding: 16px 32px;
	background: #fff;
	box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08);
	z-index: 100;
}

.summaryRow {
	font-weight: 600;
	line-height: 22px;
	letter-spacing: 0.25px;
	color: #252525;
}

.summaryRow :nth-child(3) {
	text-align: right;
}

.summaryRow :nth-child(1),
.summaryRow :nth-child(2) {
	border-right: none !important;
}

.errorText {
	font-size: 16px;
	line-height: 24px;
	margin-top: 12px;
	color: var(--error-100);
}

.damageGrid,
.damageRowGrid,
.damageSelectorRow {
	display: grid;
	gap: 16px;
}

.damageRow,
.damageSelectorRow {
	padding: 16px;
	border: 1px solid var(--bg-50);
}

.damageSelectorRow {
	grid-template-columns: 1fr max-content;
	align-items: center;
}

.damageSelector {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 16px;
}

.quantityButton {
	width: 32px !important;
	height: 32px !important;
	display: grid;
	place-items: center;
	padding: 0 !important;
	background: #ffffff !important;
	box-shadow: none;
}

.quantityButtonRemove {
	border: 1px solid var(--error-100) !important;
}

.quantityButton img {
	width: 20px;
}

.deliveryPartnerCell:not(th) {
	padding: 0 !important;
}

.underStocked {
	color: var(--error-110);
}

.warnings {
	position: sticky;
	bottom: 88px;
	right: 0;
	display: grid;
	gap: 6px;
	z-index: 11;
}

.warning {
	font-size: 15px;
	letter-spacing: 0.25px;
	display: flex;
	align-items: center;
	color: var(--black-100);
	padding: 16px 24px;
	margin: 0 24px;
	background: var(--error-05);
	border: 1px solid var(--error-120);
}

.warning span {
	font-weight: 600;
	color: var(--error-120);
}

.underStockedTableTitle,
.inactiveProductsTableTitle {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	margin-bottom: 24px;
	color: #242529;
}

.orderId {
	background: #f2f8fa;
	padding: 8px 24px;
	margin-right: auto;
	margin-left: 16px;
	color: #288ea5;
	font-weight: bold;
	font-size: 16px;
}

.discountToggle {
	cursor: pointer;
	font-size: 11px;
	color: white;
	background: #288ea5;
	padding: 2px 6px;
	margin-right: auto;
}

.discountToggle:hover {
	background: #1c6372;
}

.deliveryPartnerForm {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 10px;
}

.receivableAmount {
	font-size: 12px;
	margin-bottom: 12px;
}

.notificationContent {
    max-height: 420px;
    overflow-y: scroll;
}

.onHoldView {
	display: flex;
	flex-direction: column;
	row-gap: 16px;
}

.infoFooter {
	display: flex;
	font-size: 12px;
	column-gap: 8px;
	line-height: 20px;
}

.preOrderTag {
	margin-top: 4px;
	padding: 2px 6px;
	font-size: 10px;
	text-wrap: nowrap;
	display: inline-block;
	background-color: #2196F31A;
	color: #2196F3
}

.tag {
	padding: 2px 6px;
	font-size: 10px;
	text-wrap: nowrap;
	display: inline-block;
}