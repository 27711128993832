.buttonContainer {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 24px 24px 24px 96px;
	background: white;
	z-index: 9;
}

.formHeader {
	font-size: 14px;
	color: #00171D;
	display: flex;
	align-items: center;
    display: flex;
	gap: 4px;
}

.smallFormHeader {
	font-size: 12px;
	color: black;
	font-weight: bold;
	margin-bottom: 6px;
}

.appOptionContainer {
	padding: 16px;
	background: #FAFAFA;
	border: 1px solid #FAFAFA;
	display: flex;
}

.appOptionContainer:hover {
	background: #F2F8FA;
	border: 1px solid #288EA5;
	cursor: pointer;
}

.appOptionContainerSelected {
	padding: 16px;
	background: #F2F8FA;
	border: 1px solid #288EA5;
	display: flex;
}

.appIcon {
	width: 48px;
	height: 48px;
}

.mainHeader {
	color: #0F3E49;
	font-size: 18px;
}

.settingsContainer {
	padding: 24px;
	border: 1px solid #F2F2F2;
	margin-bottom: 48px;
}

.smallSettingsContainer {
	padding: 16px !important;
	border: 1px solid #F2F2F2;
}

.noSmallSettingsContainer {
	margin-bottom: 0 !important;
}

.counter {
	position: absolute;
	right: 0;
	bottom: 0;
	margin-right: 12px;
	margin-bottom: 12px;
}

.alert {
	width: 320px;
}

.addOn {
	align-self: center;
	padding: 12px;
	border-right: 1px solid #d9d9d9;
	border-top: 1px solid #d9d9d9;
	border-bottom: 1px solid #d9d9d9;
}

.subscriptionInfoCardsContainer {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 15px;
}

.subscriptionInfoCard {
	padding: 10px;
	border: 1px solid #F2F2F2;
}

.cardHeaderContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-right: 10px;
}

.cardHeader {
	margin: 0px;
	color: #00171D;
	font-size: 16px;
	font-weight: 500;
}

.subscriptionStatusContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
	gap: 5px;
	margin-bottom: 12px;
}

.subscriptionStatus {
	color: #0F3E49;
	font-size: 15px;
	font-weight: 500;
}

.subscriptionPlan {
	color: #288EA5;
	font-size: 15px;
	font-weight: 500;
}

.subscriptionPeriod {
	color: #0F3E49;
	font-size: 12px;
}

.limitDefaultContainer {
	margin-top: 18px;
}

.limitHeaderText {
	color: #656565;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 0px;
}

.limitInfo {
	color: #00171D;
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 15px;
}

.subscriptionUsageContainer {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	margin-top: 10px;
}

.editLimitButtonsContainer {
	display: flex;
	flex-direction: row;
	gap: 5px
}

.trialBadge {
	background-color: #FFFBF4;
	color: #C48F3F;
	font-size: 11px;
	padding: 5px 10px;
}

.success {
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    padding: 12px;
    border: 1px solid #F2F2F2;
    width: 175px;
}

.syncProcessingStockContainer {
	display: flex;
	flex-direction: row;
	margin-top: 20px;
}

.tabs {
	display: flex;
	gap: 24px;
	background: white;
	width: 100%;
	padding: 15px 0;
	position: sticky;
	top: 124px;
	z-index: 1;
	left: 96px;
	margin-top: -1px;
}