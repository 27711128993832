.title {
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 12px;
}

.inputGroup {
	display: flex !important;
	justify-content: flex-start;
	flex-wrap: wrap;
	row-gap: 12px;
}

.inputFlex {
	display: flex;
	gap: 8px;
}

.page {
	width: 210mm;
	height: 278mm;
	padding: 12mm;
	margin-bottom: 24px;
	display: flex;
	flex-direction: column;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
	line-height: 1;
	page-break-before: always;
	background: white;
	overflow: hidden;
	position: relative;
	color: black;
}

.pageInvisible {
	width: 210mm;
	height: 278mm;
	padding: 12mm;
	display: flex;
	flex-direction: column;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
	line-height: 1;
	page-break-before: always;
	background: white;
	display: none;
	overflow: hidden;
	position: relative;
	color: black;
}

.header {
    font-size: 11px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.footer {
    font-size: 11px;
    font-weight: bold;
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 128px;
}

.signature {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1;
    align-items: center;
}

.line {
    width: 100%;
    border-top: 1px solid black;
    margin-top: 12px;
}

@media print {
	.page {
		display: flex;
	}

	.pageInvisible {
		display: flex;
	}

	.posPage {
		display: flex;
	}

	.posPageInvisible {
		display: flex;
	}
}
